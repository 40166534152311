// 查询 - 个人信息
const userInfoURL = `/gateway/hc-mini/user/mini/user-info`;

// 查询 - 托幼预约 - 列表
const getTyyyListUrl = `/gateway/hc-serve/oldDataInfo/getOldDataInfoPage`;

// 查询 - 托幼预约 - 详情
const getTyyyDetailUrl = `/gateway/hc-serve/oldDataInfo/getOldDataInfoById`;

// 保存 - 托幼预约 - 信息
const saveTyyyInfoUrl = `/gateway/hc-serve/oldDataInfo/applyOldDataInfo`;

export { userInfoURL, getTyyyListUrl, getTyyyDetailUrl, saveTyyyInfoUrl };
